
import React from "react";
import { XIcon } from '@heroicons/react/solid';

export const WarningModal = ({ setShowWarningModal }) => {
  return (
    <>
      <div className="fixed z-50 inset-0 h-screen w-screen bg-gray-800 bg-opacity-70 overflow-auto p-2 flex justify-center items-center">
        <div className="w-56 h-40 bg-gray-200 rounded-2xl relative text-black flex items-center justify-center flex-col px-5 text-center text-sm pt-6">
          
      <button 
            className="absolute top-1 right-1 bg-red-500 text-white rounded-full text-xs cursor-pointer"
            style={{ transform: 'translate(50%, -50%)' }}
          >
            <XIcon className="h-6 w-6 text-white" style={{padding:'4px'}} onClick={()=>setShowWarningModal(false)}/>
          </button>
            <p>You can only compare up to 4 products at a time</p>
            <button className="mt-8 font-semibold whitespace-nowrap inline-flex items-center justify-center px-6 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white hover:text-gray-800 uppercase" onClick={()=>setShowWarningModal(false)}>OK</button>
        </div>
      
      </div>
    </>
  );
}