import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { XIcon, TrashIcon } from '@heroicons/react/solid';
export const CompareSelected = ({selectedProducts, setShowCompareModal, products, handleSelectProduct,handleClearAll}) => {
  const selectedProductsData = selectedProducts.map(product => products.find(p => p.name === product)).filter(product => product)
  return(
    <>
    <div className="fixed z-50 bottom-0 w-screen bg-gray-800 h-40 lg:h-24 text-white">
      <div
      className="mx-auto max-w-screen-lg flex flex-col lg:flex-row justify-center gap-5 lg:gap-20 items-center h-full px-4"
      >
        <ul className="flex gap-1 md:gap-6 justify-center flex-nowrap">
       {selectedProductsData.map((product, index) => {
         const midpoint = Math.ceil(product.name.length / 2);
         let splitPoint = midpoint;
         while (product.name[splitPoint] !== ' ' && splitPoint < product.name.length) {
           splitPoint++;
         }
         if (splitPoint === product.name.length) {
           splitPoint = midpoint;
           while (product.name[splitPoint] !== ' ' && splitPoint > 0) {
             splitPoint--;
           }
         }

  const firstHalf = product.name.substring(0, splitPoint);
  const secondHalf = product.name.substring(splitPoint + 1);
        return (
          <li key={index} className="text-center text-xs lg:text-sm font-semibold py-1 w-20 md:w-32 border border-white relative">
          <button 
            className="z-10 absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs cursor-pointer"
            style={{ transform: 'translate(50%, -50%)' }}
          >
            <XIcon className="h-4 w-4 text-white" style={{padding:'2px'}} onClick={()=>handleSelectProduct(product.name)}/>
          </button>
          {firstHalf}
          <br />
          {secondHalf}
        </li>
        )
       })}
       </ul>
       <div className="flex gap-4">
        {selectedProducts.length > 1 && <button className="font-semibold whitespace-nowrap inline-flex items-center justify-center px-4 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white hover:text-gray-800 uppercase"
        onClick={()=>setShowCompareModal(true)}>
          Compare
        </button>}
        <button className="flex justify-center items-center gap-2" onClick={()=>handleClearAll()}>
          <TrashIcon className="h-6 w-6 text-white"/> Clear All
        </button>
       </div>
        

      </div>
    </div>
    </>
  )
}